import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDashboardList(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.state.customConfig.baseURL}/web/dashboard/getlist`, {
            apikey: this.state.customConfig.token,
            perPage: payload.perPage,
            currentPage: payload.currentPage,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDashboardDetail(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.state.customConfig.baseURL}/web/dashboard/getdetail`, {
            apikey: this.state.customConfig.token,
            patrol_id: payload.id,
            area_id: payload.area_id,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelDashboard(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.state.customConfig.baseURL}/web/guard/cancelpatrol`, {
            apikey: this.state.customConfig.token,
            patrol_id: payload.id,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDashboardFullList(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.state.customConfig.baseURL}/web/dashboard/getfull`, {
            apikey: this.state.customConfig.token,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
