<template>
  <div id="dashboard-patrol-full">
    <!-- Table Container Card -->
    <b-card
      no-body
    >
    </b-card>
    <div v-for="item in dataDashboard" :key="item.id">
      <dashboardProgressCircleFull :data="item" :idModal="item.id" />
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import {
  onUnmounted, ref, watch, computed,
} from '@vue/composition-api'
import store from '@/store'
import dashboardStoreModule from '@/views/dashboard/dashboardStoreModule'
import dashboardProgressCircleFull from '@/views/dashboard/dashboardProgressCircleFull.vue'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    dashboardProgressCircleFull,
  },
  data() {
    return {
      interval: null,
      isVerticalMenuCollapsed: this.$store.state.verticalMenu.isVerticalMenuCollapsed,
    }
  },
  methods: {
  },
  created() {
    // Set `appConfig` when page/SFC is created
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
  },
  destroyed() {
    // Restore the state value of `appConfig` when page/SFC is destroyed
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', this.isVerticalMenuCollapsed)
  },
  mounted(){
    this.fetchDashboards()
    let self = this
    this.interval = setInterval(function() {
      console.log('masuk interval')
      self.fetchDashboards()
    }, 15000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-dashboard-full-view'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, dashboardStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })


    // Use toast
    const toast = useToast()
    
    const dataDashboard = ref(null)

    const fetchDashboards = (ctx, callback) => {
      store
        .dispatch('app-dashboard-full-view/fetchDashboardFullList')
        .then(response => {
          // console.log('ini api')
          // console.log(response)
          // console.log(response.data.data)
          // const data = response.data.data.list
          // callback(data)
          dataDashboard.value = response.data.data
        })
        .catch(() => {
          // toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Error fetching Dashboard list",
          //     icon: 'AlertTriangleIcon',
          //     variant: 'danger',
          //   },
          // })
        })
    }

    return {
      dataDashboard,
      toast,

      fetchDashboards,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
